import rewritePattern from "regexpu-core";

const validateMixin = {
  methods: {
    validateValueAgainstPattern(value, pattern) {
      const regex = RegExp(
        rewritePattern(pattern, "u", {
          unicodePropertyEscape: true,
        })
      );
      return regex.test(value);
    },
    validateValueHasNoWww(value) {
      const pattern = "^((?!www\\.).)*$";
      return this.validateValueAgainstPattern(value, pattern);
    },
    validateValueAlphaNumeric(value) {
      const pattern =
        "^[\\p{Script_Extensions=Latin}\\p{Zs}\\p{Nd}\\p{Pd}().,]+$";
      return this.validateValueAgainstPattern(value, pattern);
    },
    validateValueAlphaNumericSpecial(value) {
      const pattern =
        "^[\\p{Script_Extensions=Latin}\\p{Zs}\\p{Nd}\\p{Pd}\\p{Pc}()'&$.:,+*]+$";
      return this.validateValueAgainstPattern(value, pattern);
    },
    getTooltip(country, field) {
      const example = country.validation[field].example.replaceAll("*", "");
      return `${this.$t("companyRegistration.formFieldsTooltip")} ${example}`;
    },
    showErrorForField(fieldName) {
      return this.errors && this.errors.fields[fieldName]
        ? this.$t(`validation.${this.errors.fields[fieldName]}`)
        : "";
    },
    showMappedErrorForField(fieldName) {
      return this.mappedServerErrors && this.mappedServerErrors[fieldName]
        ? this.$t(`validation.${this.mappedServerErrors[fieldName]}`)
        : "";
    },
  },
};

export { validateMixin };
