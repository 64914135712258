<template>
  <div>
    <h3 class="step-title">
      {{ $t(`companyRegistration.formStepDetails${type}Address`) }}
    </h3>

    <template v-if="type === 'Billing'">
      <CDCheckbox
        name="billingAddressSameAsBusiness"
        color="primary"
        :checked="billingAddressSameAsBusiness"
        :label="$t('companyRegistration.formBillingAddress')"
        @change="toggleBillingAddress"
      />
    </template>
    <div v-if="type === 'Billing' ? !billingAddressSameAsBusiness : true">
      <div class="row">
        <div class="col col-6">
          <CDInput
            name="street"
            dense
            maxlength="40"
            :value="street"
            :label="$t('companyRegistration.formFields.street.label')"
            :rules="validation.street"
            :error-messages="
              showErrorForField(`${typeLowerCase}Address.street`)
            "
            @change="updateStreet"
          />
        </div>
        <div class="col col-6">
          <CDInput
            name="houseNumber"
            dense
            maxlength="10"
            :value="houseNumber"
            :label="$t('companyRegistration.formFields.houseNumber.label')"
            :rules="validation.houseNumber"
            :error-messages="
              showErrorForField(`${typeLowerCase}Address.houseNumber`)
            "
            @change="updateHouseNumber"
          />
        </div>
      </div>

      <div class="row">
        <div class="col">
          <CDInput
            name="postalCode"
            dense
            maxlength="10"
            :value="postalCode"
            :label="$t('companyRegistration.formFields.postalCode.label')"
            :rules="validation.postalCode"
            :error-messages="
              showErrorForField(`${type.toLowerCase()}Address.postalCode`)
            "
            @change="updatePostalCode"
          >
            <template v-if="country" v-slot:append>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on"> $info </v-icon>
                </template>
                <span>{{ getTooltip(country, "postalCode") }}</span>
              </v-tooltip>
            </template>
          </CDInput>
        </div>
        <div class="col">
          <CDInput
            name="city"
            dense
            maxlength="40"
            :value="city"
            :label="$t('companyRegistration.formFields.city.label')"
            :rules="validation.city"
            :error-messages="showErrorForField(`${typeLowerCase}Address.city`)"
            @change="updateCity"
          />
        </div>
      </div>
      <div class="row mb-4" v-if="hasRegions">
        <div class="col">
          <CDSelect
            name="region"
            dense
            solo
            item-value="abbrev"
            :value="region"
            :label="$t('companyRegistration.formFields.region.label')"
            :items="regions"
            :item-text="(item) => `${item.name}`"
            :error-messages="
              showErrorForField(`${typeLowerCase}Address.region`)
            "
            :rules="validation.region"
            @change="updateRegion"
          />
        </div>
      </div>

      <div class="row mb-4" v-if="hasStates">
        <div class="col">
          <CDSelect
            name="state"
            dense
            solo
            item-value="abbrev"
            :value="state"
            :label="$t('companyRegistration.formFields.state.label')"
            :items="states"
            :item-text="(item) => `${item.name} (${item.abbrev})`"
            :rules="validation.state"
            :error-messages="showErrorForField(`${typeLowerCase}Address.state`)"
            @change="updateState"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validateMixin } from "@/utils/mixins";
import { mutationTypes } from "@/store/mutations";
import { mapState } from "vuex";
import { VTooltip, VIcon } from "vuetify/lib";

export default {
  name: "CompanyRegistrationFormDetailsAddress",
  props: {
    type: String,
  },
  components: { VTooltip, VIcon },
  mixins: [validateMixin],
  data() {
    return {
      validation: {
        street: [
          (value) => {
            return (
              (this.validateValueHasNoWww(value) &&
                this.validateValueAlphaNumericSpecial(value)) ||
              this.$t("companyRegistration.formFields.street.validation")
            );
          },
        ],
        postalCode: [
          (value) =>
            !!value ||
            this.$t("companyRegistration.formFields.postalCode.validation"),
        ],
        houseNumber: [
          (value) => {
            return (
              (this.validateValueHasNoWww(value) &&
                this.validateValueAlphaNumeric(value)) ||
              this.$t("companyRegistration.formFields.houseNumber.validation")
            );
          },
        ],
        city: [
          (value) => {
            return (
              (this.validateValueHasNoWww(value) &&
                this.validateValueAlphaNumericSpecial(value)) ||
              this.$t("companyRegistration.formFields.city.validation")
            );
          },
        ],
        region: [
          (value) =>
            !!value ||
            this.$t("companyRegistration.formFields.region.validation"),
        ],
        state: [
          (value) =>
            !!value ||
            this.$t("companyRegistration.formFields.state.validation"),
        ],
      },
    };
  },
  computed: {
    ...mapState({
      billingAddressSameAsBusiness: (state) =>
        state.formObj.billingAddressSameAsBusiness,
      country: (state) => state.country,
      errors: (state) => state.errors,
      streetBusiness: (state) => state.formObj.streetBusiness,
      houseNumberBusiness: (state) => state.formObj.houseNumberBusiness,
      postalCodeBusiness: (state) => state.formObj.postalCodeBusiness,
      cityBusiness: (state) => state.formObj.cityBusiness,
      stateBusiness: (state) => state.formObj.stateBusiness,
      regionBusiness: (state) => state.formObj.regionBusiness,
      streetBilling: (state) => state.formObj.streetBilling,
      houseNumberBilling: (state) => state.formObj.houseNumberBilling,
      postalCodeBilling: (state) => state.formObj.postalCodeBilling,
      cityBilling: (state) => state.formObj.cityBilling,
      stateBilling: (state) => state.formObj.stateBilling,
      regionBilling: (state) => state.formObj.regionBilling,
      regions: (state) => {
        return state.country && state.country.regions
          ? state.country.regions
          : [];
      },
      hasRegions: (state) => {
        return (
          state.country &&
          state.country.regions &&
          state.country.regions.length > 0
        );
      },
      states: (state) => {
        return state.country && state.country.states
          ? state.country.states
          : [];
      },
      hasStates: (state) => {
        return (
          state.country &&
          state.country.states &&
          state.country.states.length > 0
        );
      },
    }),
    typeLowerCase: function () {
      return this.type.toLowerCase();
    },
    street: function () {
      return this.type === "Billing" ? this.streetBilling : this.streetBusiness;
    },
    houseNumber: function () {
      return this.type === "Billing"
        ? this.houseNumberBilling
        : this.houseNumberBusiness;
    },
    postalCode: function () {
      return this.type === "Billing"
        ? this.postalCodeBilling
        : this.postalCodeBusiness;
    },
    city: function () {
      return this.type === "Billing" ? this.cityBilling : this.cityBusiness;
    },
    region: function () {
      return this.type === "Billing" ? this.regionBilling : this.regionBusiness;
    },
    state: function () {
      return this.type === "Billing" ? this.stateBilling : this.stateBusiness;
    },
  },
  methods: {
    toggleBillingAddress() {
      this.$store.commit(
        mutationTypes.SET_BILLING_ADDRESS_SAME_AS_BUSINESS,
        !this.billingAddressSameAsBusiness
      );
      if (this.billingAddressSameAsBusiness) {
        this.resetBillingAddress();
      }
    },
    updateStreet(value) {
      const mutation =
        this.type === "Billing"
          ? mutationTypes.SET_BILLING_STREET
          : mutationTypes.SET_BUSINESS_STREET;
      this.$store.commit(mutation, value);
    },
    updateHouseNumber(value) {
      const mutation =
        this.type === "Billing"
          ? mutationTypes.SET_BILLING_HOUSE_NUMBER
          : mutationTypes.SET_BUSINESS_HOUSE_NUMBER;
      this.$store.commit(mutation, value);
    },
    updatePostalCode(value) {
      const mutation =
        this.type === "Billing"
          ? mutationTypes.SET_BILLING_POSTAL_CODE
          : mutationTypes.SET_BUSINESS_POSTAL_CODE;
      this.$store.commit(mutation, value);
    },
    updateCity(value) {
      const mutation =
        this.type === "Billing"
          ? mutationTypes.SET_BILLING_CITY
          : mutationTypes.SET_BUSINESS_CITY;
      this.$store.commit(mutation, value);
    },
    updateRegion(value) {
      const mutation =
        this.type === "Billing"
          ? mutationTypes.SET_BILLING_REGION
          : mutationTypes.SET_BUSINESS_REGION;
      this.$store.commit(mutation, value);
    },
    updateState(value) {
      const mutation =
        this.type === "Billing"
          ? mutationTypes.SET_BILLING_STATE
          : mutationTypes.SET_BUSINESS_STATE;
      this.$store.commit(mutation, value);
    },
    resetBillingAddress() {
      this.$store.commit(mutationTypes.SET_BILLING_STREET, "");
      this.$store.commit(mutationTypes.SET_BILLING_HOUSE_NUMBER, "");
      this.$store.commit(mutationTypes.SET_BILLING_POSTAL_CODE, "");
      this.$store.commit(mutationTypes.SET_BILLING_CITY, "");
      this.$store.commit(mutationTypes.SET_BILLING_STATE, "");
      this.$store.commit(mutationTypes.SET_BILLING_REGION, "");
    },
  },
  watch: {
    country(country) {
      const newValidationFunction = (value) => {
        return (
          this.validateValueAgainstPattern(
            value,
            country.validation.postalCode.pattern
          ) || this.$t("companyRegistration.formFields.postalCode.validation")
        );
      };
      this.validation.postalCode.pop();
      this.validation.postalCode.push(newValidationFunction);
    },
  },
};
</script>
