<template>
  <v-form ref="activationForm" class="activationForm">
    <v-container>
      <v-row>
        <v-col>
          <Subheader
            v-bind:title="$t('signupConfirm')"
            v-bind:subtitle="$t('signupHeader', { company })"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <CDInput
            name="email"
            dense
            disabled
            :value="email"
            :label="$t('signupEmail')"
          >
            <template v-slot:prepend>
              <v-icon color="primary">mdi-email-outline</v-icon>
            </template>
          </CDInput>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <CDInput
            name="firstName"
            dense
            maxlength="40"
            v-model="firstName"
            :label="$t('firstName')"
            :rules="validation.firstName"
            :error-messages="showMappedErrorForField('firstName')"
          >
            <template v-slot:prepend>
              <v-icon color="primary">mdi-account-outline</v-icon>
            </template>
          </CDInput>
        </v-col>
        <v-col>
          <CDInput
            name="lastName"
            dense
            maxlength="40"
            v-model="lastName"
            :label="$t('lastName')"
            :rules="validation.lastName"
            :error-messages="showMappedErrorForField('lastName')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <CDInput
            name="password"
            dense
            type="password"
            v-model="password"
            :label="$t('password')"
            :rules="validation.password"
            :error-messages="showMappedErrorForField('password')"
          >
            <template v-slot:prepend>
              <v-icon color="primary">mdi-lock-outline</v-icon>
            </template>
          </CDInput>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <CDInput
            name="passwordConfirm"
            dense
            type="password"
            v-model="passwordConfirm"
            :label="$t('passwordConfirm')"
            :rules="validation.passwordConfirm"
            :error-messages="showMappedErrorForField('passwordConfirm')"
          >
            <template v-slot:prepend>
              <v-icon color="primary">mdi-lock-outline</v-icon>
            </template>
          </CDInput>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <NewsletterSubscribeCheckbox
            :agree="newsletterSubscribe"
            @updateNewsletterSubscribe="toggleNewsletterSubscribe"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <TermsAndConditionsAgreementCheckbox
            :agree="agreeTermsAndPrivacy"
            @updateAgreeTermsAndPrivacy="toggleAgreeTermsAndPrivacy"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <PrivacyProtectionNoticeCheckbox
            :agree="agreePrivacyProtectionNotice"
            @updateAgreePrivacyProtectionNotice="
              toggleAgreePrivacyProtectionNotice
            "
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="buttons">
            <CDButton
              name="activate"
              color="primary"
              large
              :disabled="isDisabled"
              @click="activate()"
              >{{ $t("signupActivate") }}
            </CDButton>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import Subheader from "./Subheader.vue";
import { mapApiResponseFieldsToFormFields } from "@/utils";
import NewsletterSubscribeCheckbox from "./NewsletterSubscribeCheckbox";
import TermsAndConditionsAgreementCheckbox from "./TermsAndConditionsAgreementCheckbox";
import PrivacyProtectionNoticeCheckbox from "./PrivacyProtectionNoticeCheckbox";
import { validateMixin } from "@/utils/mixins";
import { VForm, VContainer, VRow, VCol, VIcon } from "vuetify/lib";

export default {
  name: "ActivationForm",
  components: {
    NewsletterSubscribeCheckbox,
    Subheader,
    TermsAndConditionsAgreementCheckbox,
    PrivacyProtectionNoticeCheckbox,
    VForm,
    VContainer,
    VRow,
    VCol,
    VIcon,
  },
  mixins: [validateMixin],
  props: {
    company: String,
    email: String,
    serverErrors: {},
    isDisabled: Boolean,
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      password: "",
      passwordConfirm: "",
      newsletterSubscribe: false,
      agreeTermsAndPrivacy: false,
      agreePrivacyProtectionNotice: false,
      varServerErrors: {},
      validation: {
        firstName: [
          (value) => {
            const pattern =
              "^[\\p{L}]{1}[\\p{L}\\p{Zs}\\p{Pd}\\p{Pc}()'&$.:,+/*]*$";
            return (
              this.validateValueAgainstPattern(value, pattern) ||
              this.$t("companyRegistration.formFields.firstName.validation")
            );
          },
        ],
        lastName: [
          (value) => {
            const pattern =
              "^[\\p{L}]{1}[\\p{L}\\p{Zs}\\p{Pd}\\p{Pc}()'&$.:,+/*]*$";
            return (
              this.validateValueAgainstPattern(value, pattern) ||
              this.$t("companyRegistration.formFields.lastName.validation")
            );
          },
        ],
        password: [
          (value) =>
            value.length >= 12 ||
            this.$t("validation.PASSWORD_DOES_NOT_CONFORM_RULES"),
          () => {
            this.emptyServerErrors();
            return true;
          },
        ],
        passwordConfirm: [
          (value) =>
            value.length >= 12 ||
            this.$t("validation.PASSWORD_DOES_NOT_CONFORM_RULES"),
          (value) =>
            value === this.password ||
            this.$t("validation.PASSWORDS_DO_NOT_MATCH"),
        ],
      },
    };
  },
  computed: {
    mappedServerErrors: function () {
      return mapApiResponseFieldsToFormFields(this.varServerErrors);
    },
  },
  methods: {
    toggleNewsletterSubscribe(value) {
      this.newsletterSubscribe = value;
    },
    toggleAgreeTermsAndPrivacy(value) {
      this.agreeTermsAndPrivacy = value;
    },
    toggleAgreePrivacyProtectionNotice(value) {
      this.agreePrivacyProtectionNotice = value;
    },
    activate() {
      if (!this.$refs.activationForm.validate()) return;

      this.$emit("activate", {
        firstName: this.firstName,
        lastName: this.lastName,
        password: this.password,
        passwordConfirm: this.passwordConfirm,
        newsletterSubscribe: this.newsletterSubscribe,
      });
    },
    showMappedErrorForField(fieldName) {
      return this.mappedServerErrors && this.mappedServerErrors[fieldName]
        ? this.$t(`validation.${this.mappedServerErrors[fieldName]}`)
        : "";
    },
    emptyServerErrors() {
      this.varServerErrors = {};
    },
  },
  mounted() {
    this.varServerErrors = this.serverErrors;
  },
  watch: {
    serverErrors: function (errors) {
      this.varServerErrors = errors;
    },
  },
};
</script>

<style lang="scss">
@import "../styles/globals/constants.scss";

.activationForm {
  min-width: 640px;

  @media screen and (max-width: $mobile-brakepoint) {
    min-width: auto;
  }
}

.row {
  margin-top: 0 !important;
}
.v-input--selection-controls {
  margin-top: 0 !important;
}
</style>
