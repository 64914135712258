<template>
  <div class="registration-profile">
    <h3>
      {{ $t("companyRegistration.registeredCompanyInfo") }}
    </h3>
    <CompanyRegistrationFormCountry />
    <div class="row">
      <div class="col col-6">
        <CDInput
          name="companyName"
          dense
          maxlength="60"
          :value="companyName"
          :label="$t('companyRegistration.formFields.companyName.label')"
          :rules="validation.companyName"
          :error-messages="showErrorForField('companyName')"
          @change="updateCompanyName"
        />
      </div>
      <div class="col col-6">
        <CDInput
          name="taxId"
          dense
          maxlength="20"
          :value="taxId"
          :label="$t('companyRegistration.formFields.taxId.label')"
          :rules="validation.taxId"
          :error-messages="showErrorForField('taxId')"
          @change="updateTaxId"
        >
          <template v-if="country" v-slot:append>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on"> $info </v-icon>
              </template>
              <span>{{ getTooltip(country, "taxId") }}</span>
            </v-tooltip>
          </template>
        </CDInput>
      </div>
    </div>
  </div>
</template>

<script>
import CompanyRegistrationFormCountry from "@/components/CompanyRegistrationFormCountry";
import { validateMixin } from "@/utils/mixins";
import { mapState } from "vuex";
import { mutationTypes } from "@/store/mutations";
import { VTooltip, VIcon } from "vuetify/lib";

export default {
  name: "CompanyRegistrationFormProfile",
  components: { CompanyRegistrationFormCountry, VTooltip, VIcon },
  mixins: [validateMixin],
  data() {
    return {
      validation: {
        companyName: [
          (value) => {
            const pattern =
              "^[\\p{Script_Extensions=Latin}\\p{Zs}\\p{Nd}\\p{Pd}\\p{Pc}()'&$.:,+/*]+$";
            return (
              (this.validateValueHasNoWww(value) &&
                this.validateValueAgainstPattern(value, pattern)) ||
              this.$t("companyRegistration.formFields.companyName.validation")
            );
          },
        ],
        taxId: [
          (value) =>
            !!value ||
            this.$t("companyRegistration.formFields.taxId.validation"),
        ],
      },
    };
  },
  computed: {
    ...mapState({
      country: (state) => state.country,
      companyName: (state) => state.formObj.companyName,
      taxId: (state) => state.formObj.taxId,
      errors: (state) => state.errors,
    }),
  },
  methods: {
    updateCompanyName(value) {
      this.$store.commit(mutationTypes.SET_COMPANY_NAME, value);
    },
    updateTaxId(value) {
      this.$store.commit(mutationTypes.SET_TAX_ID, value);
    },
  },
  watch: {
    country(country) {
      const newValidationFunction = (value) => {
        return (
          this.validateValueAgainstPattern(
            value,
            country.validation.taxId.pattern
          ) || this.$t("companyRegistration.formFields.taxId.validation")
        );
      };
      this.validation.taxId.pop();
      this.validation.taxId.push(newValidationFunction);
    },
  },
};
</script>
