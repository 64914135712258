<template>
  <div>
    <p class="subtitle">
      <i18n path="companyRegistration.formCountryNotAvailable.template">
        <a slot="link" href="https://www.azena.com/contact" target="_blank">
          {{ $t("companyRegistration.formCountryNotAvailable.linkLabel") }}
        </a>
      </i18n>
    </p>
    <div class="row">
      <div class="col country-col">
        <CDSelect
          name="country"
          dense
          solo
          return-object
          item-value="isoCode"
          :loading="isLoading"
          :label="$t('companyRegistration.formFields.country.label')"
          :value="country"
          :items="countries"
          :item-text="(item) => `${item.name} (${item.isoCode})`"
          :rules="validation.country"
          @change="selectCountry($event)"
        />
      </div>
    </div>
    <div class="row" v-if="showNotification">
      <div class="col notification">
        <v-icon color="info">$statusinfo</v-icon>
        <p class="notification-message">
          {{ $t(notification) }}
          <a
            href="https://support.azena.com/hc/en-us/articles/360043913854-Which-countries-are-currently-supported"
            target="_blank"
            >{{ $t("clickHereToLearnMore") }}</a
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mutationTypes } from "@/store/mutations";
import { VIcon } from "vuetify/lib";

export default {
  name: "CompanyRegistrationFormCountry",
  components: {
    VIcon,
  },
  data() {
    return {
      notification: "",
      validation: {
        country: [
          (value) =>
            !!value ||
            this.$t("companyRegistration.formFields.country.validation"),
        ],
      },
    };
  },
  computed: {
    isCompanyPurchaseEnabled() {
      return this.companyTypes.includes("INTEGRATOR");
    },
    isCompanySalesEnabled() {
      return this.companyTypes.includes("DEVELOPER");
    },
    isCompanyPurchaseAndSalesEnabled() {
      return this.isCompanyPurchaseEnabled && this.isCompanySalesEnabled;
    },
    isCountryPurchaseEnabled() {
      return this.country.canBuy;
    },
    isCountrySalesEnabled() {
      return this.country.canSell;
    },
    isCountryPurchaseAndSalesEnabled() {
      return this.isCountryPurchaseEnabled && this.isCountrySalesEnabled;
    },
    showNotification() {
      return this.country && this.notification;
    },
    ...mapState({
      companyTypes: (state) => state.companyTypes,
      isLoading: (state) => state.isLoading,
      countries: (state) => state.countries,
      country: (state) => state.country,
    }),
  },
  methods: {
    selectCountry(country) {
      this.$store.commit(mutationTypes.SET_COUNTRY, country);
      this.setNotificationMessageIfNeeded();
    },
    setNotificationMessageIfNeeded() {
      if (!this.country) return;

      if (
        this.isCompanyPurchaseAndSalesEnabled &&
        this.isCountryPurchaseAndSalesEnabled
      ) {
        this.notification = "";
        return;
      }

      if (this.isCompanyPurchaseAndSalesEnabled) {
        if (this.isCountryPurchaseEnabled && !this.isCountrySalesEnabled) {
          this.notification =
            "supportedCountriesNotifications.companyPurchaseAndSalesEnabled.purchaseEnabled";
        } else if (
          !this.isCountryPurchaseEnabled &&
          this.isCountrySalesEnabled
        ) {
          this.notification =
            "supportedCountriesNotifications.companyPurchaseAndSalesEnabled.salesEnabled";
        } else {
          this.notification =
            "supportedCountriesNotifications.companyPurchaseAndSalesEnabled.countryEnabled";
        }
        return;
      }

      if (this.isCompanyPurchaseEnabled) {
        if (this.isCountryPurchaseEnabled && this.isCountrySalesEnabled) {
          this.notification = "";
        } else if (
          this.isCountryPurchaseEnabled &&
          !this.isCountrySalesEnabled
        ) {
          this.notification = "";
        } else if (
          !this.isCountryPurchaseEnabled &&
          this.isCountrySalesEnabled
        ) {
          this.notification =
            "supportedCountriesNotifications.companyPurchaseEnabled.salesEnabled";
        } else {
          this.notification =
            "supportedCountriesNotifications.companyPurchaseEnabled.countryEnabled";
        }
        return;
      }

      if (this.isCompanySalesEnabled) {
        if (this.isCountryPurchaseEnabled && this.isCountrySalesEnabled) {
          this.notification = "";
        } else if (
          this.isCountryPurchaseEnabled &&
          !this.isCountrySalesEnabled
        ) {
          this.notification =
            "supportedCountriesNotifications.companySalesEnabled.purchaseEnabled";
        } else if (
          !this.isCountryPurchaseEnabled &&
          this.isCountrySalesEnabled
        ) {
          this.notification = "";
        } else {
          this.notification =
            "supportedCountriesNotifications.companySalesEnabled.countryEnabled";
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../styles/globals/constants.scss";

.notification {
  display: flex;
  margin: $spacing-s auto;
  column-gap: 16px;
}

.notification-message {
  padding-top: 16px;
  color: $main-color-info;

  a {
    color: $main-color-info;
    text-decoration: underline;
  }
}

.loading {
  display: flex;
  column-gap: $spacing-xs;
  align-items: center;
  margin-bottom: $spacing-s;
}

::v-deep .v-input__slot {
  box-shadow: none !important;
}
</style>
