<template>
  <div>
    <div class="company-registration" v-if="!complete">
      <h1>{{ $t("companyTypeHeadline") }}</h1>
      <p class="subtitle">{{ $t("companyRegistration.formDesc") }}</p>
      <CompanyRegistrationProgressBar :step="step" :complete="complete" />
      <p class="submit-error" v-if="errors.errorSending">
        {{
          $t(
            "companyRegistration.errorSending['" +
              errors.errorSendingCode +
              "']"
          )
        }}
      </p>
      <v-form ref="companyRegistrationForm">
        <CompanyRegistrationFormProfile v-if="step === 2" />
        <div class="registration-details">
          <CompanyRegistrationFormDetailsAddress
            v-if="step === 2"
            type="Business"
          />
          <CompanyRegistrationFormDetailsAddress
            v-if="step === 2"
            type="Billing"
          />
        </div>
        <CompanyRegistrationFormContact v-if="step === 3" />
        <div class="btn-actions">
          <div>
            <CDButtonTextIcon
              icon="mdi-chevron-left"
              color="primary"
              @click="goBack"
            >
              {{ $t("companyRegistration.formBtnBack") }}
            </CDButtonTextIcon>
          </div>
          <CDButton
            v-if="step !== 3"
            name="continue"
            color="primary"
            large
            v-on:click="goNext()"
            >{{ $t("continue") }}
          </CDButton>
          <CDButton
            v-if="step === 3"
            name="send"
            color="primary"
            large
            :loading="isLoading"
            v-on:click="send()"
            >{{
              isLoading
                ? $t("companyRegistration.formBtnSending")
                : $t("companyRegistration.formBtnSend")
            }}
          </CDButton>
        </div>
      </v-form>
    </div>

    <div class="company-registration-message" v-if="complete">
      <h1>{{ $t("companyRegistration.successHeader") }}</h1>
      <br />
      <i18n path="companyRegistration.successEmailSent1" tag="p">
        <span slot="email">{{ email }}</span>
      </i18n>
      <i18n path="companyRegistration.successEmailSent2.template" tag="p">
        <a slot="link" href="/company">{{
          $t("companyRegistration.successEmailSent2.linkLabel")
        }}</a>
      </i18n>
    </div>
  </div>
</template>

<style lang="scss">
@import "../styles/components/company-registration.scss";
</style>

<script>
import CompanyRegistrationFormProfile from "./CompanyRegistrationFormProfile";
import CompanyRegistrationFormContact from "./CompanyRegistrationFormContact";
import CompanyRegistrationFormDetailsAddress from "./CompanyRegistrationFormDetailsAddress";
import CompanyRegistrationProgressBar from "./CompanyRegistrationProgressBar";
import { mapState } from "vuex";
import { actionTypes } from "@/store/actions";
import { mutationTypes } from "@/store/mutations";
import { VForm } from "vuetify/lib";

export default {
  name: "CompanyRegistrationForm",
  components: {
    CompanyRegistrationProgressBar,
    CompanyRegistrationFormProfile,
    CompanyRegistrationFormContact,
    CompanyRegistrationFormDetailsAddress,
    VForm,
  },
  data() {
    return {
      errorSending: false,
      errorSendingCode: "fallback",
    };
  },
  async created() {
    await this.$store.dispatch(actionTypes.GET_COUNTRIES);
  },
  computed: {
    ...mapState({
      step: (state) => state.step,
      isLoading: (state) => state.isLoading,
      country: (state) => state.country,
      complete: (state) => state.complete,
      email: (state) => state.formObj.email,
      errors: (state) => state.errors,
    }),
  },
  methods: {
    goNext() {
      if (!this.$refs.companyRegistrationForm.validate()) return;
      this.$store.dispatch(actionTypes.GO_NEXT);
    },

    goBack() {
      this.step === 2
        ? this.$router.push("company")
        : this.$store.commit(mutationTypes.SET_STEP, this.step - 1);
    },

    send() {
      if (!this.$refs.companyRegistrationForm.validate()) return;
      this.$store.dispatch(actionTypes.SEND_COMPANY_REGISTRATION);
    },
  },
};
</script>
