<template>
  <div>
    <div v-if="isVerified">
      <h1>{{ $t("companyRegistration.emailVerifiedHeader") }}</h1>
      <p class="subtitle">{{ $t("companyRegistration.emailVerified") }}</p>
      <i18n path="companyRegistration.emailVerified2.template" tag="p">
        <span slot="applicationStoreLink">
          <a slot="linkAppStoreTerms" v-bind:href="storeLink" target="_blank">
            {{
              $t("companyRegistration.emailVerified2.applicationStoreLinkLabel")
            }}
          </a>
        </span>
        <span slot="helpAndSupportLink">
          <a
            slot="linkAppStoreTerms"
            href="https://support.azena.com"
            target="_blank"
          >
            {{
              $t("companyRegistration.emailVerified2.helpAndSupportLinkLabel")
            }}
          </a>
        </span>
      </i18n>
    </div>
    <div v-else-if="hasError">
      <h1>{{ $t("companyRegistration.emailVerifiedHeaderError") }}</h1>
      <p class="subtitle">{{ $t("companyRegistration.emailVerifiedError") }}</p>
    </div>
    <div v-else>
      <h1>{{ $t("companyRegistration.loading") }}</h1>
    </div>
  </div>
</template>

<script>
import api from "../api";

export default {
  name: "CompanyRegisterVerify",
  data() {
    return {
      isVerified: null,
      hasError: null,
      storeLink: null,
    };
  },
  beforeCreate() {
    const companyId = this.$route.query["companyId"],
      token = this.$route.query["token"];

    if (!companyId && !token) {
      this.$router.push("/");
    } else {
      api
        .companyVerify(companyId, { token: token })
        .then(() => {
          this.isVerified = true;
          this.hasError = false;
        })
        .catch(() => {
          this.isVerified = false;
          this.hasError = true;
        });

      this.$router.replace({ query: null });
    }
  },

  created() {
    this.storeLink =
      "//" +
      location.host
        .replace(/sso\.accounts/, "store")
        .replace(/sso\./, "store.");
  },
};
</script>
