<template>
  <div>
    <h1>{{ $t("accountDeletionHeader") }}</h1>
    <br />
    <i18n path="accountDeletion.template" tag="p">
      <a slot="link" href="https://support.azena.com/hc">{{
        $t("accountDeletion.linkLabel")
      }}</a>
    </i18n>
  </div>
</template>

<script>
export default {
  name: "AccountDeletionSuccess",
  beforeCreate() {
    const success = this.$route.query["success"];

    if (!success) {
      this.$router.push("/");
    } else {
      this.$router.replace({ query: null });
    }
  },
};
</script>
