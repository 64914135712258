<template>
  <CDCheckbox
    name="agreePrivacyProtectionNotice"
    color="primary"
    :checked="agree"
    :rules="validation.agreePrivacyProtectionNotice"
    @change="toggleAgreePrivacyProtectionNotice"
  >
    <template v-slot:label>
      <div>
        {{
          $t(
            "companyRegistration.formFields.agreePrivacyProtectionNotice.label.template"
          )
        }}
        <a target="_blank" :href="sastLinks.PRIVACY_POLICY_URL" @click.stop>
          {{
            $t(
              "companyRegistration.formFields.agreePrivacyProtectionNotice.label.linkText"
            )
          }}
        </a>
      </div>
    </template>
  </CDCheckbox>
</template>

<script>
import { sastLinks } from "@/utils/enums";

export default {
  name: "PrivacyProtectionNoticeCheckbox",
  props: {
    agree: Boolean,
  },
  data() {
    return {
      sastLinks,
      validation: {
        agreePrivacyProtectionNotice: [
          (value) =>
            !!value ||
            this.$t(
              "companyRegistration.formFields.agreePrivacyProtectionNotice.validation"
            ),
        ],
      },
    };
  },
  methods: {
    toggleAgreePrivacyProtectionNotice() {
      this.$emit("updateAgreePrivacyProtectionNotice", !this.agree);
    },
  },
};
</script>
