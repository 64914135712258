<template>
  <div class="company-type">
    <CDNotificationPersistent type="warning">
      <i18n path="azenaDisclaimer.disclaimer.line1" tag="p">
        <a slot="link" href="https://legal.azena.com/assets/pdf/FAQ_AzenaPlatformUsers_20221205.pdf" target="_blank">
          {{$t("azenaDisclaimer.partnerFAQ")}}</a>
      </i18n>
      <i18n path="azenaDisclaimer.disclaimer.line2" tag="p"> </i18n>
    </CDNotificationPersistent>

    <h1>{{ $t("companyTypeHeadline") }}</h1>
    <p class="subtitle">{{ $t("companyTypeHeadDescription") }}</p>

    <div class="types-container">
      <div role="button" v-on:click="typeClicked(['INTEGRATOR'])">
        <div class="img-container">
          <img src="../img/integrators-2-x.png" />
        </div>
        <p>{{ $t("companyTypeIntegrator") }}</p>
      </div>
      <div role="button" v-on:click="typeClicked(['DEVELOPER'])">
        <div class="img-container">
          <img src="../img/developers-2-x.png" />
        </div>
        <p>{{ $t("companyTypeDeveloper") }}</p>
      </div>
      <div role="button" v-on:click="typeClicked(['INTEGRATOR', 'DEVELOPER'])">
        <div class="img-container both">
          <img src="../img/integrators-2-x.png" />
          <img src="../img/developers-2-x.png" />
        </div>
        <p>{{ $t("companyTypeIntegratorAndDeveloper") }}</p>
      </div>
    </div>

    <i18n path="companyTypePleaseLogIn.template" tag="p">
      <a slot="link" href="https://accounts.azena.com">{{
        $t("companyTypePleaseLogIn.linkLabel")
      }}</a>
    </i18n>
  </div>
</template>

<style lang="scss">
@import "../styles/company-type.scss";
</style>

<script>
export default {
  name: "CompanyType",
  methods: {
    typeClicked(companyTypes) {
      window.dataLayer.push({
        event: "signup-step-1-company-type-selected",
        companyTypes: companyTypes.join("+"),
      });
      this.$router.push({
        name: "company-register",
        params: { companyTypes: companyTypes },
      });
    },
  },
};
</script>
