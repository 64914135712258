import Vue from "vue";
import Vuetify from "vuetify/lib";

import {
  CDButton,
  CDButtonTextIcon,
  CDCheckbox,
  CDFooter,
  CDInput,
  CDSelect,
  CDNotificationPersistent,
  CDCookieBanner,
  CDNeedHelpButton,
  options,
} from "cd-system";
import "cd-system/tokens/fonts/fonts.css"; // url-loader: 'npm i url-loader'
import "@mdi/font/css/materialdesignicons.css"; // @mdi/font: 'npm i @mdi/font'
import "material-design-icons/iconfont/material-icons.css"; // npm i material-design-icons

Vue.component("CDButton", CDButton);
Vue.component("CDButtonTextIcon", CDButtonTextIcon);
Vue.component("CDFooter", CDFooter);
Vue.component("CDInput", CDInput);
Vue.component("CDSelect", CDSelect);
Vue.component("CDCheckbox", CDCheckbox);
Vue.component("CDNotificationPersistent", CDNotificationPersistent);
Vue.component("CDCookieBanner", CDCookieBanner);
Vue.component("CDNeedHelpButton", CDNeedHelpButton);

Vue.use(Vuetify);

export default new Vuetify(options);
