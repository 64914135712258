<template>
  <div>
    <h1 v-if="statusCode">{{ $t("signupFailure." + statusCode) }}</h1>
    <p>
      <template v-if="statusCode">
        {{ $t("signupFailureReason." + statusCode) }}
      </template>
      <template v-if="statusCode && statusCode != 409">
        {{ $t("defaultRouteText") }}
        <a href="https://www.azena.com">www.azena.com</a>
      </template>
    </p>
  </div>
</template>

<script>
export default {
  name: "Error",
  props: ["statusCode"],
};
</script>
