<template>
  <CDCheckbox
    name="agreeTermsAndPrivacy"
    color="primary"
    :checked="agree"
    :rules="validation.agreeTermsAndPrivacy"
    @change="toggleAgreeTermsAndPrivacy"
  >
    <template v-slot:label>
      <div>
        {{
          $t(
            "companyRegistration.formFields.agreeTermsAndPrivacy.label.template"
          )
        }}
        <a
          target="_blank"
          :href="sastLinks.TERMS_AND_CONDITIONS_URL"
          @click.stop
        >
          {{
            $t(
              "companyRegistration.formFields.agreeTermsAndPrivacy.label.linkText"
            )
          }}
        </a>
      </div>
    </template>
  </CDCheckbox>
</template>

<script>
import { sastLinks } from "@/utils/enums";

export default {
  name: "TermsAndConditionsAgreementCheckbox",
  props: {
    agree: Boolean,
  },
  data() {
    return {
      sastLinks,
      validation: {
        agreeTermsAndPrivacy: [
          (value) =>
            !!value ||
            this.$t(
              "companyRegistration.formFields.agreeTermsAndPrivacy.validation"
            ),
        ],
      },
    };
  },
  methods: {
    toggleAgreeTermsAndPrivacy() {
      this.$emit("updateAgreeTermsAndPrivacy", !this.agree);
    },
  },
};
</script>
