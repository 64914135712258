<template>
  <div v-if="companyTypes">
    <CompanyRegistrationForm />
  </div>
</template>

<script>
import CompanyRegistrationForm from "../components/CompanyRegistrationForm";
import { mutationTypes } from "../store/mutations";

export default {
  name: "CompanyRegister",
  components: {
    CompanyRegistrationForm,
  },
  data() {
    return {
      companyTypes: this.$route.params.companyTypes,
    };
  },
  beforeCreate() {
    if (!this.$route.params.companyTypes) {
      this.$router.push("company");
    }

    this.$store.commit(
      mutationTypes.SET_COMPANY_TYPES,
      this.$route.params.companyTypes
    );
  },
};
</script>
