/**
 * @param err: response from server
 */
export default (err) => {
  const errors = {};
  const statusCode = err.response ? err.response.status : null;

  if ([403, 404, 409, 410].includes(statusCode)) {
    errors["statusCode"] = statusCode;
  } else if (statusCode === 400 && err.response.data.inputViolations) {
    for (let violation of err.response.data.inputViolations) {
      errors[violation.field] = violation.reason;
    }
    errors["statusCode"] = "400";
  } else {
    errors["statusCode"] = "fallback";
  }
  return errors;
};
