const config = () => {
  const hostname = window && window.location && window.location.hostname;
  const isSandbox = hostname.includes("sandbox.securityandsafetydev.com");
  const isDev = hostname.includes("camdroid.io");
  const isDemo = hostname.includes("securityandsafetydemo.io");
  const isLive = hostname.includes("azena.com");
  const isLocalhost = !isSandbox && !isDev && !isDemo && !isLive;

  let domain = "localhost";
  if (isSandbox) {
    domain = "sandbox.securityandsafetydev.com";
  } else if (isDev) {
    domain = "camdroid.io";
  } else if (isDemo) {
    domain = "securityandsafetydemo.io";
  } else if (isLive) {
    domain = "azena.com";
  }

  const protocol = isLocalhost ? "http" : "https";
  const port = isLocalhost ? ":4200" : "";
  const url = `${protocol}://${domain}${port}/`;

  return {
    domain,
    url,
  };
};

export default config;
