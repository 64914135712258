import { mutationTypes } from "./mutations";
import api from "../api";
import processError from "@/utils/processError";

const actionTypes = {
  GET_COUNTRIES: "GET_COUNTRIES",
  GO_NEXT: "GO_NEXT",
  SEND_COMPANY_REGISTRATION: "SEND_COMPANY_REGISTRATION",
};

const mapStateToRegistrationDto = ({ state }) => {
  return {
    companyTypes: state.companyTypes,
    companyName: state.formObj.companyName,
    country: state.formObj.country,
    businessAddress: {
      street: state.formObj.streetBusiness,
      houseNumber: state.formObj.houseNumberBusiness,
      postalCode: state.formObj.postalCodeBusiness,
      city: state.formObj.cityBusiness,
      state: state.formObj.stateBusiness,
      region: state.formObj.regionBusiness,
    },
    billingAddress: state.formObj.billingAddressSameAsBusiness
      ? null
      : {
          street: state.formObj.streetBilling,
          houseNumber: state.formObj.houseNumberBilling,
          postalCode: state.formObj.postalCodeBilling,
          city: state.formObj.cityBilling,
          state: state.formObj.stateBilling,
          region: state.formObj.regionBilling,
        },
    taxId: state.formObj.taxId,
    email: state.formObj.email,
    firstName: state.formObj.firstName,
    lastName: state.formObj.lastName,
    website: state.formObj.website || null,
    isNewsletterSubscribe: state.formObj.newsletterSubscribe,
    agreeTermsAndPrivacy: state.formObj.agreeTermsAndPrivacy,
  };
};

const actions = {
  [actionTypes.GET_COUNTRIES]: async (context) => {
    context.commit(mutationTypes.SET_IS_LOADING, true);
    try {
      const sortedCountries = (await api.getCountries()).data
        .slice()
        .sort((a, b) => (a.name >= b.name ? 1 : -1));
      context.commit(mutationTypes.SET_COUNTRIES, sortedCountries);
    } finally {
      context.commit(mutationTypes.SET_IS_LOADING, false);
    }
  },
  [actionTypes.GO_NEXT]: (context) => {
    window.dataLayer.push({
      event: "signup-step-2-company-details-done",
      country: context.state.country.isoCode,
      companyTypes: context.state.companyTypes.join("+"),
    });

    context.commit(mutationTypes.SET_STEP, context.state.step + 1);
  },
  [actionTypes.SEND_COMPANY_REGISTRATION]: async (context) => {
    context.commit(mutationTypes.SET_IS_LOADING, true);

    const registrationDto = mapStateToRegistrationDto(context);

    api
      .companyRegister(registrationDto)
      .then(() => {
        window.dataLayer.push({
          event: "signup-step-3-form-submitted",
          country: context.state.country.isoCode,
        });
        context.commit(mutationTypes.SET_COMPLETE, true);
      })
      .catch((error) => {
        const processedError = processError(error);
        let errorSendingCode = "";

        // eslint-disable-next-line no-prototype-builtins
        if (processedError.hasOwnProperty("statusCode")) {
          errorSendingCode = processedError.statusCode;
        } else if (processedError.length === 0) {
          errorSendingCode = "fallback";
        }

        const errors = {
          fields: processedError,
          errorSending: true,
          errorSendingCode,
        };

        context.commit(mutationTypes.SET_ERRORS, errors);
      })
      .finally(() => {
        context.commit(mutationTypes.SET_IS_LOADING, false);
      });
  },
};

export { actionTypes, actions };
