const mapApiResponseFieldsToFormFields = (fields) => {
  for (const field in fields) {
    if (field === "passwordDto.password") {
      fields = renameKey(fields, "passwordDto.password", "password");
    }
    if (field === "passwordConfirmDto.password") {
      fields = renameKey(
        fields,
        "passwordConfirmDto.password",
        "passwordConfirm"
      );
    }
  }

  return fields;
};

const renameKey = (object, oldKey, newKey) => {
  Object.defineProperty(
    object,
    newKey,
    Object.getOwnPropertyDescriptor(object, oldKey)
  );
  delete object[oldKey];
  return object;
};

export { mapApiResponseFieldsToFormFields };
