import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "../lang/en.json";

Vue.use(VueI18n);

const fallbackLanguage = "en";
const supportedLanguages = ["en"];

export const i18n = new VueI18n({
  locale: setLanguage(),
  fallbackLocale: fallbackLanguage,
  messages: { en },
});

function setLanguage() {
  let browserLanguage = navigator.language.slice(0, 2);
  return supportedLanguages.includes(browserLanguage)
    ? browserLanguage
    : fallbackLanguage;
}
