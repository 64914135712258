import axios from "axios";

const baseUrl = "/rest/public/signup";
const countriesBaseUrl = "/rest/public/countries";

const userSignup = (user) => {
  return axios.post(baseUrl, user);
};

const validateInvitation = (token) => {
  return axios.get(`${baseUrl}?invitationToken=${token}`);
};

const companyRegister = (company) => {
  return axios.post(`${baseUrl}/company`, company);
};

const companyVerify = (companyId, body) => {
  return axios.post(`${baseUrl}/company/${companyId}/verify`, body);
};

const getUserInfo = (companyId, token) => {
  return axios.get(`${baseUrl}/company/${companyId}/account?token=${token}`);
};

const companyUserSignup = (companyId, userId, body) => {
  return axios.post(
    `${baseUrl}/company/${companyId}/user/${userId}/activate`,
    body
  );
};

const companyActivateLegacy = (companyId, body) => {
  return axios.post(`${baseUrl}/company/${companyId}/activateLegacy`, body);
};

const getCountries = () => {
  return axios.get(`${countriesBaseUrl}/registration`);
};

export default {
  userSignup,
  validateInvitation,
  companyRegister,
  companyVerify,
  getUserInfo,
  companyUserSignup,
  companyActivateLegacy,
  getCountries,
};
