<template>
  <CDCheckbox
    name="newsletterSubscribe"
    color="primary"
    :checked="agree"
    :label="$t('companyRegistration.formSubscribeNewsletter')"
    @change="toggleNewsletterSubscribe"
  />
</template>

<script>
export default {
  name: "NewsletterSubscribeCheckbox",
  props: {
    agree: Boolean,
  },
  methods: {
    toggleNewsletterSubscribe() {
      this.$emit("updateNewsletterSubscribe", !this.agree);
    },
  },
};
</script>
