<template>
  <div class="registration-contact">
    <CDInput
      name="email"
      dense
      maxlength="254"
      :value="email"
      :label="$t('companyRegistration.formFields.email.label')"
      :rules="validation.email"
      :error-messages="showErrorForField('email')"
      @change="updateEmail"
    />
    <div class="row">
      <div class="col">
        <CDInput
          name="firstName"
          dense
          maxlength="40"
          :value="firstName"
          :label="$t('companyRegistration.formFields.firstName.label')"
          :rules="validation.firstName"
          :error-messages="showErrorForField('firstName')"
          @change="updateFirstName"
        />
      </div>
      <div class="col">
        <CDInput
          name="lastName"
          dense
          maxlength="40"
          :value="lastName"
          :label="$t('companyRegistration.formFields.lastName.label')"
          :rules="validation.lastName"
          :error-messages="showErrorForField('lastName')"
          @change="updateLastName"
        />
      </div>
    </div>
    <CDInput
      name="website"
      dense
      maxlength="254"
      :value="website"
      :label="$t('companyRegistration.formFields.website.label')"
      :rules="validation.website"
      @change="updateWebsite"
    />
    <NewsletterSubscribeCheckbox
      :agree="newsletterSubscribe"
      @updateNewsletterSubscribe="toggleNewsletterSubscribe"
    />
    <TermsAndConditionsAgreementCheckbox
      :agree="agreeTermsAndPrivacy"
      @updateAgreeTermsAndPrivacy="toggleAgreeTermsAndPrivacy"
    />
    <PrivacyProtectionNoticeCheckbox
      :agree="agreePrivacyProtectionNotice"
      @updateAgreePrivacyProtectionNotice="toggleAgreePrivacyProtectionNotice"
    />
  </div>
</template>

<script>
import TermsAndConditionsAgreementCheckbox from "./TermsAndConditionsAgreementCheckbox";
import PrivacyProtectionNoticeCheckbox from "./PrivacyProtectionNoticeCheckbox";
import { validateMixin } from "@/utils/mixins";
import { mutationTypes } from "@/store/mutations";
import { mapState } from "vuex";
import NewsletterSubscribeCheckbox from "./NewsletterSubscribeCheckbox";

export default {
  name: "CompanyRegistrationFormContact",
  data() {
    return {
      validation: {
        email: [
          (value) => {
            const pattern =
              "^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}$";
            return (
              this.validateValueAgainstPattern(value, pattern) ||
              this.$t("companyRegistration.formFields.email.validation")
            );
          },
        ],
        firstName: [
          (value) => {
            return (
              (this.validateValueHasNoWww(value) &&
                this.validateValueAlphaNumeric(value)) ||
              this.$t("companyRegistration.formFields.firstName.validation")
            );
          },
        ],
        lastName: [
          (value) => {
            return (
              (this.validateValueHasNoWww(value) &&
                this.validateValueAlphaNumeric(value)) ||
              this.$t("companyRegistration.formFields.lastName.validation")
            );
          },
        ],
        website: [
          (value) => {
            if (!value) return true;
            const pattern =
              "^$|^https?:\\/\\/(?:www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b(?:[-a-zA-Z0-9()@:%_\\+.~#?&\\/=]*)$";
            return (
              this.validateValueAgainstPattern(value, pattern) ||
              this.$t("companyRegistration.formFields.website.validation")
            );
          },
        ],
      },
    };
  },
  computed: {
    ...mapState({
      email: (state) => state.formObj.email,
      firstName: (state) => state.formObj.firstName,
      lastName: (state) => state.formObj.lastName,
      website: (state) => state.formObj.website,
      newsletterSubscribe: (state) => state.formObj.newsletterSubscribe,
      agreeTermsAndPrivacy: (state) => state.formObj.agreeTermsAndPrivacy,
      agreePrivacyProtectionNotice: (state) =>
        state.formObj.agreePrivacyProtectionNotice,
      errors: (state) => state.errors,
    }),
  },
  mixins: [validateMixin],
  components: {
    NewsletterSubscribeCheckbox,
    TermsAndConditionsAgreementCheckbox,
    PrivacyProtectionNoticeCheckbox,
  },
  methods: {
    updateEmail(value) {
      this.$store.commit(mutationTypes.SET_EMAIL, value);
    },
    updateFirstName(value) {
      this.$store.commit(mutationTypes.SET_FIRSTNAME, value);
    },
    updateLastName(value) {
      this.$store.commit(mutationTypes.SET_LASTNAME, value);
    },
    updateWebsite(value) {
      this.$store.commit(mutationTypes.SET_WEBSITE, value);
    },
    toggleNewsletterSubscribe(value) {
      this.$store.commit(mutationTypes.SET_NEWSLETTER, value);
    },
    toggleAgreeTermsAndPrivacy(value) {
      this.$store.commit(mutationTypes.SET_AGREE_TERMS_AND_PRIVACY, value);
    },
    toggleAgreePrivacyProtectionNotice(value) {
      this.$store.commit(
        mutationTypes.SET_AGREE_PRIVACY_PROTECTION_NOTICE,
        value
      );
    },
  },
};
</script>
