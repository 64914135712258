<template>
  <div class="progress-bar">
    <div
      class="progress-bar-step"
      :class="{ active: step === 1, complete: step > 1 }"
    >
      <div class="progress-bar-step-icon"></div>
      <div class="progress-bar-step-label">
        {{ $t("companyRegistration.businessType") }}
      </div>
    </div>

    <div
      class="progress-bar-step"
      :class="{ active: step === 2, complete: step > 2 }"
    >
      <div class="progress-bar-step-icon"></div>
      <div class="progress-bar-step-label">
        {{ $t("companyRegistration.formStepDetails") }}
      </div>
    </div>

    <div class="progress-bar-step" :class="{ active: step === 3 }">
      <div class="progress-bar-step-icon"></div>
      <div class="progress-bar-step-label">
        {{ $t("companyRegistration.formStepContact") }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompanyRegistrationProgressBar",
  props: {
    step: Number,
    complete: Boolean,
  },
};
</script>

<style lang="scss">
@import "../styles/components/progress-bar.scss";
</style>
