<template>
  <div>
    <div class="company-registration company-registration-activate">
      <div v-if="!activationSuccessful">
        <div v-if="userId && email && firstName && lastName">
          <div class="mb-4">
            <h1>{{ $t("companyRegistration.accountActivateHeader") }}</h1>
            <p>{{ $t("companyRegistration.accountActivate") }}</p>
          </div>
          <CDNotificationPersistent
            v-if="showError"
            type="error"
            class="mb-8"
            >{{
              $t("companyRegistration.errorSending['" + errorStatusCode + "']")
            }}</CDNotificationPersistent
          >
          <v-form ref="companyActivationForm">
            <v-container>
              <v-row>
                <v-col>
                  <CDInput
                    name="email"
                    dense
                    disabled
                    :value="email"
                    :label="$t('companyRegistration.formFields.email.label')"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <CDInput
                    name="firstName"
                    dense
                    disabled
                    :value="firstName"
                    :label="
                      $t('companyRegistration.formFields.firstName.label')
                    "
                  />
                </v-col>
                <v-col>
                  <CDInput
                    name="lastName"
                    dense
                    disabled
                    :value="lastName"
                    :label="$t('companyRegistration.formFields.lastName.label')"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <CDInput
                    name="password"
                    dense
                    type="password"
                    v-model="password"
                    :label="$t('password')"
                    :rules="validation.password"
                    :error-messages="showMappedErrorForField('password')"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <CDInput
                    name="passwordConfirm"
                    dense
                    type="password"
                    v-model="passwordConfirm"
                    :label="$t('passwordConfirm')"
                    :rules="validation.passwordConfirm"
                  />
                </v-col>
              </v-row>
            </v-container>
            <div class="btn-actions">
              <div></div>
              <CDButton
                name="activate"
                color="primary"
                large
                :loading="activating"
                @click="activate()"
                >{{
                  activating
                    ? $t("companyRegistration.formBtnActivating")
                    : $t("companyRegistration.formBtnActivate")
                }}
              </CDButton>
            </div>
          </v-form>
        </div>
        <div v-else-if="errorToken">
          <h1>{{ $t("companyRegistration.accountActivateHeaderError") }}</h1>
          <p>{{ $t("companyRegistration.accountActivateError") }}</p>
        </div>
        <div v-else>
          <h1>{{ $t("companyRegistration.loading") }}</h1>
        </div>
      </div>
      <div v-else>
        <div class="mb-4">
          <h1>
            {{ $t("companyRegistration.accountActivationSuccessHeader") }}
          </h1>
          <i18n path="companyRegistration.accountActivationSuccess1" tag="p">
            <span slot="email">{{ email }}</span>
            <span slot="company">{{ company }}</span>
          </i18n>
          <p>
            {{ $t("companyRegistration.accountActivationSuccess2") }}
          </p>
        </div>
        <div class="btn-actions">
          <div></div>
          <CDButton
            name="continueToLogin"
            color="primary"
            large
            @click="continueToLogin()"
            >{{ $t("continue") }}
          </CDButton>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "../styles/components/company-registration.scss";
</style>

<script>
import api from "../api";
import processError from "../utils/processError";
import { VForm, VContainer, VRow, VCol } from "vuetify/lib";
import { mapApiResponseFieldsToFormFields } from "@/utils";
import { validateMixin } from "@/utils/mixins";
export default {
  name: "CompanyRegisterActivate",
  components: {
    VForm,
    VContainer,
    VRow,
    VCol,
  },
  mixins: [validateMixin],
  data() {
    return {
      userId: "",
      email: "",
      firstName: "",
      lastName: "",
      company: "",
      companyTypes: ["DEVELOPER"],
      password: "",
      passwordConfirm: "",
      serverErrors: [],
      activating: false,
      activationSuccessful: false,
      errorToken: false,
      showError: false,
      errorStatusCode: Number,
      validation: {
        password: [
          (value) =>
            value.length >= 12 ||
            this.$t("validation.PASSWORD_DOES_NOT_CONFORM_RULES"),
          () => {
            this.emptyErrors();
            return true;
          },
        ],
        passwordConfirm: [
          (value) =>
            value.length >= 12 ||
            this.$t("validation.PASSWORD_DOES_NOT_CONFORM_RULES"),
          (value) =>
            value === this.password ||
            this.$t("validation.PASSWORDS_DO_NOT_MATCH"),
        ],
      },
    };
  },
  beforeCreate() {
    const companyId = this.$route.query["companyId"],
      token = this.$route.query["token"];

    if (!companyId || !token) {
      this.$router.push("/");
    } else {
      api
        .getUserInfo(companyId, token)
        .then((response) => {
          this.userId = response.data.userName || "";
          this.email = response.data.email || "";
          this.firstName = response.data.firstName || "";
          this.lastName = response.data.lastName || "";
          this.company = response.data.company || "";
          this.companyTypes = response.data.companyTypes || "";
        })
        .catch(() => {
          this.errorToken = true;
        });
    }
  },
  computed: {
    mappedServerErrors: function () {
      return mapApiResponseFieldsToFormFields(this.serverErrors);
    },
  },
  methods: {
    mapInputToData(field, event) {
      this[field] = event.detail;
    },

    isNotEmpty(el) {
      return el !== "" && el.length >= 12;
    },

    isEqual(el1, el2) {
      return el1 === el2;
    },

    activate() {
      if (!this.$refs.companyActivationForm.validate()) return;

      const companyId = this.$route.query["companyId"];
      const token = this.$route.query["token"];

      this.activating = true;

      api
        .companyUserSignup(companyId, this.userId, {
          token: token,
          passwordDto: this.password,
          passwordConfirmDto: this.passwordConfirm,
        })
        .then(() => {
          this.activationSuccessful = true;
        })
        .catch((error) => {
          const processedError = processError(error);
          this.serverErrors = processedError;
          if (
            Object.prototype.hasOwnProperty.call(
              processedError,
              "statusCode"
            ) &&
            !Object.prototype.hasOwnProperty.call(
              processedError,
              "passwordDto.password"
            )
          ) {
            this.errorStatusCode = processedError.statusCode;
            this.showError = true;
          }
        })
        .finally(() => {
          this.activating = false;
        });
    },

    continueToLogin() {
      if (this.companyTypes.includes("INTEGRATOR")) {
        location.href =
          "//" +
          location.host
            .replace(/sso\.accounts/, "devices")
            .replace(/sso\./, "devices.");
      } else {
        location.href =
          "//" +
          location.host
            .replace(/sso\.accounts/, "console")
            .replace(/sso\./, "console.");
      }
    },
    emptyErrors() {
      this.serverErrors = {};
    },
  },
};
</script>
