import featureFlags from "../../../shared-ui-libs/src/featureFlags";

const state = {
  isLoading: false,
  companyTypes: [],
  countries: [],
  country: "",
  step: 2,
  complete: false,
  errors: {
    fields: [],
    errorSending: false,
    errorSendingCode: "fallback",
  },
  formObj: {
    companyName: "",
    country: "",
    streetBusiness: "",
    houseNumberBusiness: "",
    postalCodeBusiness: "",
    cityBusiness: "",
    stateBusiness: "",
    regionBusiness: "",
    billingAddressSameAsBusiness: true,
    streetBilling: "",
    houseNumberBilling: "",
    postalCodeBilling: "",
    cityBilling: "",
    stateBilling: "",
    regionBilling: "",
    taxId: "",
    email: "",
    firstName: "",
    lastName: "",
    website: "",
    newsletterSubscribe: false,
    agreeTermsAndPrivacy: false,
    agreePrivacyProtectionNotice: false,
  },
  featureFlags,
};

export default state;
