import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { i18n } from "./plugins/i18n";
import vuetify from "./plugins/vuetify";
import store from "./store";

Vue.config.productionTip = false;

window.dataLayer = window.dataLayer || [];

// It will change the title when the route is changed
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

new Vue({
  router,
  i18n,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");
