import Vue from "vue";
import Router from "vue-router";
import CompanyType from "./views/CompanyType";
import Signup from "./views/Signup";
import Error from "./views/Error";
import CompanyRegister from "./views/CompanyRegister";
import CompanyRegisterVerify from "./views/CompanyRegisterVerify";
import CompanyRegisterActivate from "./views/CompanyRegisterActivate";
import AccountDeletionSuccess from "./views/AccountDeletionSuccess";
import CompanyActivateLegacy from "./views/CompanyActivateLegacy";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/signup",
      component: Signup,
      props: (route) => ({ token: route.query.token }),
    },
    {
      name: "company-register",
      path: "/company-register",
      component: CompanyRegister,
      meta: {
        title: "Azena Company Register",
      },
    },
    {
      path: "*",
      component: Error,
      props: { statusCode: 404 },
    },
    {
      path: "/company",
      component: CompanyType,
      meta: {
        title: "Azena Company Register",
      },
    },
    {
      path: "/company-register-verify",
      component: CompanyRegisterVerify,
      meta: {
        title: "Azena Company Register Verify",
      },
    },
    {
      path: "/company-register-activate",
      component: CompanyRegisterActivate,
      meta: {
        title: "Azena Company Register Activate",
      },
    },
    {
      path: "/company-activate",
      component: CompanyActivateLegacy,
      meta: {
        title: "Azena Company Activate",
      },
    },
    {
      path: "/account-deleted",
      component: AccountDeletionSuccess,
      meta: {
        title: "Azena Company Account Deleted",
      },
    },
  ],
});
