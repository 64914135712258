<template>
  <div class="subheader">
    <h1>{{ title }}</h1>
    <p v-if="subtitle" class="subtitle">{{ subtitle }}</p>
  </div>
</template>

<script>
export default {
  name: "Subheader",
  props: ["title", "subtitle"],
};
</script>
