const mutationTypes = {
  SET_COMPANY_TYPES: "SET_COMPANY_TYPES",
  SET_IS_LOADING: "SET_IS_LOADING",
  SET_COUNTRIES: "SET_COUNTRIES",
  SET_COUNTRY: "SET_COUNTRY",
  SET_STEP: "SET_STEP",
  SET_COMPLETE: "SET_COMPLETE",
  SET_COMPANY_NAME: "SET_COMPANY_NAME",
  SET_TAX_ID: "SET_TAX_ID",
  SET_BUSINESS_STREET: "SET_BUSINESS_STREET",
  SET_BUSINESS_HOUSE_NUMBER: "SET_BUSINESS_HOUSE_NUMBER",
  SET_BUSINESS_POSTAL_CODE: "SET_BUSINESS_POSTAL_CODE",
  SET_BUSINESS_CITY: "SET_BUSINESS_CITY",
  SET_BUSINESS_REGION: "SET_BUSINESS_REGION",
  SET_BUSINESS_STATE: "SET_BUSINESS_STATE",
  SET_BILLING_ADDRESS_SAME_AS_BUSINESS: "SET_BILLING_ADDRESS_SAME_AS_BUSINESS",
  SET_BILLING_STREET: "SET_BILLING_STREET",
  SET_BILLING_HOUSE_NUMBER: "SET_BILLING_HOUSE_NUMBER",
  SET_BILLING_POSTAL_CODE: "SET_BILLING_POSTAL_CODE",
  SET_BILLING_CITY: "SET_BILLING_CITY",
  SET_BILLING_REGION: "SET_BILLING_REGION",
  SET_BILLING_STATE: "SET_BILLING_STATE",
  SET_EMAIL: "SET_EMAIL",
  SET_FIRSTNAME: "SET_FIRSTNAME",
  SET_LASTNAME: "SET_LASTNAME",
  SET_WEBSITE: "SET_WEBSITE",
  SET_NEWSLETTER: "SET_NEWSLETTER_SUBSCRIBE",
  SET_AGREE_TERMS_AND_PRIVACY: "SET_AGREE_TERMS_AND_PRIVACY",
  SET_AGREE_PRIVACY_PROTECTION_NOTICE: "SET_AGREE_PRIVACY_PROTECTION_NOTICE",
  SET_ERRORS: "SET_ERRORS",
};

const mutations = {
  [mutationTypes.SET_COMPANY_TYPES]: (state, types) => {
    state.companyTypes = types;
  },
  [mutationTypes.SET_IS_LOADING]: (state, isLoading) => {
    state.isLoading = isLoading;
  },
  [mutationTypes.SET_COUNTRIES]: (state, countries) => {
    state.countries = countries;
  },
  [mutationTypes.SET_STEP]: (state, step) => {
    state.step = step;
  },
  [mutationTypes.SET_COMPLETE]: (state, complete) => {
    state.complete = complete;
  },
  [mutationTypes.SET_COUNTRY]: (state, country) => {
    state.country = country;
    state.formObj.country = country.isoCode;
  },
  [mutationTypes.SET_COMPANY_NAME]: (state, companyName) => {
    state.formObj.companyName = companyName;
  },
  [mutationTypes.SET_TAX_ID]: (state, taxId) => {
    state.formObj.taxId = taxId;
  },
  [mutationTypes.SET_BUSINESS_STREET]: (state, street) => {
    state.formObj.streetBusiness = street;
  },
  [mutationTypes.SET_BUSINESS_HOUSE_NUMBER]: (state, number) => {
    state.formObj.houseNumberBusiness = number;
  },
  [mutationTypes.SET_BUSINESS_POSTAL_CODE]: (state, code) => {
    state.formObj.postalCodeBusiness = code;
  },
  [mutationTypes.SET_BUSINESS_CITY]: (state, city) => {
    state.formObj.cityBusiness = city;
  },
  [mutationTypes.SET_BUSINESS_REGION]: (state, region) => {
    state.formObj.regionBusiness = region;
  },
  [mutationTypes.SET_BUSINESS_STATE]: (state, stateBusiness) => {
    state.formObj.stateBusiness = stateBusiness;
  },
  [mutationTypes.SET_BILLING_ADDRESS_SAME_AS_BUSINESS]: (state, same) => {
    state.formObj.billingAddressSameAsBusiness = same;
  },
  [mutationTypes.SET_BILLING_STREET]: (state, street) => {
    state.formObj.streetBilling = street;
  },
  [mutationTypes.SET_BILLING_HOUSE_NUMBER]: (state, number) => {
    state.formObj.houseNumberBilling = number;
  },
  [mutationTypes.SET_BILLING_POSTAL_CODE]: (state, code) => {
    state.formObj.postalCodeBilling = code;
  },
  [mutationTypes.SET_BILLING_CITY]: (state, city) => {
    state.formObj.cityBilling = city;
  },
  [mutationTypes.SET_BILLING_REGION]: (state, region) => {
    state.formObj.regionBilling = region;
  },
  [mutationTypes.SET_BILLING_STATE]: (state, stateBilling) => {
    state.formObj.stateBilling = stateBilling;
  },
  [mutationTypes.SET_EMAIL]: (state, email) => {
    state.formObj.email = email;
  },
  [mutationTypes.SET_FIRSTNAME]: (state, firstName) => {
    state.formObj.firstName = firstName;
  },
  [mutationTypes.SET_LASTNAME]: (state, lastName) => {
    state.formObj.lastName = lastName;
  },
  [mutationTypes.SET_WEBSITE]: (state, website) => {
    state.formObj.website = website;
  },
  [mutationTypes.SET_NEWSLETTER]: (state, newsletter) => {
    state.formObj.newsletterSubscribe = newsletter;
  },
  [mutationTypes.SET_AGREE_TERMS_AND_PRIVACY]: (state, terms) => {
    state.formObj.agreeTermsAndPrivacy = terms;
  },
  [mutationTypes.SET_AGREE_PRIVACY_PROTECTION_NOTICE]: (state, privacy) => {
    state.formObj.agreePrivacyProtectionNotice = privacy;
  },
  [mutationTypes.SET_ERRORS]: (state, errors) => {
    state.errors = errors;
  },
};

export { mutationTypes, mutations };
