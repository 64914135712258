<template>
  <div>
    <div v-if="showForm && !showErrorPage">
      <ActivationForm
        v-if="!activated"
        v-bind:company="company"
        v-bind:email="email"
        v-bind:isDisabled="isDisabled"
        v-bind:serverErrors="errors"
        v-on:activate="activate"
      />
      <Welcome
        v-else
        v-bind:company="company"
        v-bind:companyTypes="companyTypes"
        v-bind:email="email"
      />
    </div>
    <Error
      v-else-if="showErrorPage"
      v-bind:status-code="errorStatusCode"
    ></Error>
  </div>
</template>

<script>
import api from "../api";
import ActivationForm from "../components/ActivationForm";
import Error from "./Error";
import Welcome from "../components/Welcome";
import processError from "../utils/processError";

export default {
  name: "Signup",
  components: { ActivationForm, Error, Welcome },
  props: {
    token: String,
  },

  data() {
    return {
      activated: false,
      isDisabled: false,
      company: "",
      companyTypes: [],
      email: "",
      showForm: false,
      errors: [],
      showErrorPage: false,
      errorStatusCode: Number,
    };
  },
  methods: {
    activate(event) {
      // block API requests as long as the API request is running
      this.isDisabled = true;
      api
        .userSignup({
          firstName: event.firstName,
          lastName: event.lastName,
          invitationToken: this.token,
          passwordDto: event.password,
          passwordConfirmDto: event.passwordConfirm,
          isNewsletterSubscribe: event.newsletterSubscribe,
        })
        .then(() => {
          this.activated = true;
          this.isDisabled = false;
        })
        .catch((err) => {
          this.errorHandler(err);
          this.isDisabled = false;
        });
    },
    errorHandler(error) {
      const processedError = processError(error);
      this.errors = processedError;
      if (
        Object.prototype.hasOwnProperty.call(processedError, "statusCode") &&
        !Object.prototype.hasOwnProperty.call(
          processedError,
          "passwordDto.password"
        )
      ) {
        this.errorStatusCode = processedError.statusCode;
        this.showErrorPage = true;
      }
    },
  },
  beforeMount() {
    api
      .validateInvitation(this.token)
      .then((response) => {
        this.email = response.data.email;
        this.company = response.data.companyName;
        this.companyTypes = response.data.companyTypes;
        this.showForm = true;
      })
      .catch((err) => this.errorHandler(err));
  },
};
</script>
