<template>
  <div>
    <div class="company-registration company-activate-legacy">
      <div v-if="!activationSuccessful">
        <div v-if="userId && email && firstName && lastName">
          <div class="mb-4">
            <h1>{{ $t("companyRegistration.accountActivateHeader") }}</h1>
          </div>

          <p class="submit-error" v-if="errorActivating">
            {{
              $t(
                "companyRegistration.errorSending['" +
                  errorActivatingCode +
                  "']"
              )
            }}
          </p>
          <v-form ref="companyActivationForm">
            <v-container>
              <v-row>
                <v-col>
                  <CDInput
                    name="email"
                    dense
                    color="primary"
                    disabled
                    :value="email"
                    :label="$t('companyRegistration.formFields.email.label')"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <CDInput
                    name="firstName"
                    dense
                    color="primary"
                    disabled
                    :value="firstName"
                    :label="
                      $t('companyRegistration.formFields.firstName.label')
                    "
                  />
                </v-col>
                <v-col>
                  <CDInput
                    name="lastName"
                    dense
                    color="primary"
                    disabled
                    :value="lastName"
                    :label="$t('companyRegistration.formFields.lastName.label')"
                  />
                </v-col>
              </v-row>
            </v-container>
            <div class="btn-actions">
              <div></div>
              <CDButton
                name="activate"
                color="primary"
                large
                :disabled="activating"
                @click="activate()"
                >{{
                  activating
                    ? $t("companyRegistration.formBtnActivating")
                    : $t("companyRegistration.formBtnActivate")
                }}
              </CDButton>
            </div>
          </v-form>
        </div>
        <div v-else-if="errorToken">
          <h1>{{ $t("companyRegistration.accountActivateHeaderError") }}</h1>
          <p>{{ $t("companyRegistration.accountActivateError") }}</p>
        </div>
        <div v-else>
          <h1>{{ $t("companyRegistration.loading") }}</h1>
        </div>
      </div>
      <div v-else>
        <div class="mb-4">
          <h1>
            {{ $t("companyRegistration.accountActivationSuccessHeader") }}
          </h1>
          <i18n path="companyRegistration.accountActivationSuccess1" tag="p">
            <span slot="email">{{ email }}</span>
            <span slot="company">{{ company }}</span>
          </i18n>
          <span>
            {{ $t("companyRegistration.accountActivationSuccess2") }}
          </span>
        </div>
        <div class="btn-actions">
          <div></div>
          <CDButton color="primary" large @click="continueToLogin()"
            >{{ $t("continue") }}
          </CDButton>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "../styles/components/company-registration.scss";
</style>

<script>
import api from "../api";
import processError from "../utils/processError";

export default {
  name: "CompanyActivateLegacy",
  data() {
    return {
      userId: "",
      email: "",
      firstName: "",
      lastName: "",
      company: "",
      companyTypes: ["DEVELOPER"],
      errors: {
        password: false,
        passwordConfirm: false,
      },
      activating: false,
      activationSuccessful: false,
      errorToken: false,
      errorActivating: false,
      errorActivatingCode: "fallback",
    };
  },
  beforeCreate() {
    const companyId = this.$route.query["companyId"],
      token = this.$route.query["token"];

    if (!companyId || !token) {
      this.$router.push("/");
    } else {
      api
        .getUserInfo(companyId, token)
        .then((response) => {
          this.userId = response.data.userName || "";
          this.email = response.data.email || "";
          this.firstName = response.data.firstName || "";
          this.lastName = response.data.lastName || "";
          this.company = response.data.company || "";
          this.companyTypes = response.data.companyTypes || "";
        })
        .catch(() => {
          this.errorToken = true;
        });
    }
  },

  methods: {
    activate() {
      const companyId = this.$route.query["companyId"],
        token = this.$route.query["token"];

      this.activating = true;

      api
        .companyActivateLegacy(companyId, {
          token: token,
        })
        .then(() => {
          this.activationSuccessful = true;
        })
        .catch((err) => {
          this.errorActivatingCode = processError(err).statusCode;
          this.errorActivating = true;
        })
        .finally(() => {
          this.activating = false;
        });
    },

    continueToLogin() {
      if (this.companyTypes.includes("INTEGRATOR")) {
        location.href =
          "//" +
          location.host
            .replace(/sso\.accounts/, "devices")
            .replace(/sso\./, "devices.");
      } else {
        location.href =
          "//" +
          location.host
            .replace(/sso\.accounts/, "console")
            .replace(/sso\./, "console.");
      }
    },
  },
};
</script>
