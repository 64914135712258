<template>
  <div class="welcome">
    <Subheader
      v-bind:title="$t('signupSuccess')"
      v-bind:subtitle="$t('signupSuccessHeader', { company, email })"
    />
    <div class="link-item" v-if="isIntegrator">
      <h2>{{ $t("integratorDescription") }}</h2>
      <a v-bind:href="integratorPortalUrl">{{ $t("integratorLink") }}</a>
    </div>
    <div class="link-item" v-if="isDeveloper">
      <h2>{{ $t("developerDescription") }}</h2>
      <a v-bind:href="developerConsoleUrl">{{ $t("developerLink") }}</a>
    </div>
  </div>
</template>

<script>
import Subheader from "./Subheader.vue";

export default {
  name: "Welcome",
  components: { Subheader },
  props: {
    company: String,
    companyTypes: Array,
    email: String,
  },
  computed: {
    developerConsoleUrl() {
      return (
        "//" +
        location.host
          .replace(/sso\.accounts/, "console")
          .replace(/sso\./, "console.")
      );
    },
    integratorPortalUrl() {
      return (
        "//" +
        location.host
          .replace(/sso\.accounts/, "devices")
          .replace(/sso\./, "devices.")
      );
    },
    isDeveloper() {
      return this.companyTypes.includes("DEVELOPER");
    },
    isIntegrator() {
      return this.companyTypes.includes("INTEGRATOR");
    },
  },
};
</script>
