const sastLinks = {
  IMPRINT_URL: "https://www.azena.com/imprint",
  TERMS_AND_CONDITIONS_URL: "https://www.azena.com/terms",
  PRIVACY_POLICY_URL: "https://www.azena.com/privacy-policy",
  LEGAL_URL: "https://www.azena.com/legal-notice",
  DISCLOSURE_POLICY_URL: "https://www.azena.com/responsible-disclosure-policy",
  REPORT_INFRIGNEMENT_URL: "https://www.azena.com/report-infringement",
  SUPPORT_URL: "https://support.azena.com/hc",
};

export { sastLinks };
